import React, { useState, useEffect, useRef } from 'react';
import { validEmailRegex, mobilenumberRegex, ifscRegex, validateForm, pan, gstNumber } from '../RegxConst'
import { Form, Button, Table } from 'react-bootstrap';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import styles from '../listings/forms/addpost.module.css';
import style from '../UIElements/Form_label/FormLabels.module.css'
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import { notifyUser, type } from '../utility/notifyUser';
import SetLocation from '../SetFilters/SetLocation/SetLocation';
import authservice from '../Authentication/service/authservice';
import axios from 'axios';
import SetCategory from '../SetFilters/SetCategory/SetCategory';
import WorkingHours from '../listings/WorkingHours';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import Popup from 'reactjs-popup';
import { FormLabels } from '../UIElements/Form_label/FormLabels';

const ManageMart = (props) => {
    const [formVisible, setFormVisible] = useState(false);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [panImage, setPanImage] = useState(null);
    const [certificateImage, setCertificateImg] = useState(null);
    const [isThumbnailPicValid, setIsThumbnailPicValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        id: null,
        userId: authservice.getCurrentUserid(),
        martName: '',
        businessDescription: '',
        thumbnailImage: null,
        thumbnailDescription: '',
        businessAddress: '',
        deletedAddresses: '',
        contactName: '',
        contactNumber: '',
        certifications: [],
        homeDeliveryAvailable: false,
        pickupAvailable: false,
        open: '',
        workingHours: [],
        categories: [],
        subCategories: [],
        country: [],
        states: [],
        cities: [],
        localities: [],
        neighbourhoods: [],
        bankName: '',
        accountHolderName: '',
        bankAccountNumber: '',
        ifsc: '',
        panNum: '',
        panImage: null,
        certificateImage: null,
        panLinkWithAadhar: false,
        gstNumber: '',
        lattitude: null,
        longitude: null,
    });
    const [marts, setMarts] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [categoryValidation, setCategoryValidation] = useState(null);
    const [subCategoryValidation, setSubCategoryValidation] = useState(null);
    const [allLocations, setAllLocations] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocality, setSelectedLocality] = useState(null);
    const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(null);
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const bankAvailabilityTimer = useRef(null);
    const [workingHours, setWorkingHours] = useState({
        monday_open: '',
        monday_close: '',
        tuesday_open: '',
        tuesday_close: '',
        wednesday_open: '',
        wednesday_close: '',
        thursday_open: '',
        thursday_close: '',
        friday_open: '',
        friday_close: '',
        saturday_open: '',
        saturday_close: '',
        sunday_open: '',
        sunday_close: '',
    });
    const [openDatePopup, setOpenDatePopup] = useState(false);
    const [date, setDate] = useState('');
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');
    const [openFrom, setOpenFrom] = useState(false);
    const [openTo, setOpenTo] = useState(false);
    const [inValidFromTime, setInValidFromTime] = useState(false);
    const [inValidToTime, setInValidToTime] = useState(false);

    const closeDatePopup = () => setOpenDatePopup(false);

    const handleFromTime = (timeString) => {
        if (toTime) {
            const fromTimeStamp = moment(timeString, 'hh:mm a');
            const toTimeStamp = moment(toTime, 'hh:mm a');
            if (toTimeStamp.isAfter(fromTimeStamp)) {
                setInValidToTime(false);
                setInValidFromTime(false);
            } else {
                setInValidToTime(true);
            }
        }
        setFromTime(timeString);
    };

    const handleToTime = (timeString) => {
        if (fromTime) {
            const fromTimeStamp = moment(fromTime, 'hh:mm a');
            const toTimeStamp = moment(timeString, 'hh:mm a');
            if (toTimeStamp.isAfter(fromTimeStamp)) {
                setInValidToTime(false);
                setInValidFromTime(false);
            } else {
                setInValidToTime(true);
            }
        }
        setToTime(timeString);
    };

    const handleOpenChangeFrom = () => {
        setOpenFrom(true);
        setOpenTo(false);
    };

    const handleOpenChangeTo = () => {
        setOpenTo(true);
        setOpenFrom(false);
    };

    const handleCloseFrom = () => setOpenFrom(false);

    const handleCloseTo = () => setOpenTo(false);


    const setWorkingHour = (timeString, timeVariableName) => {
        setWorkingHours((prev) => ({
            ...prev,
            [timeVariableName]: timeString,
        }));
    };

    const repeatOpeningHours = (timeString) => {
        setWorkingHours((prev) => ({
            ...prev,
            monday_open: timeString,
            tuesday_open: timeString,
            wednesday_open: timeString,
            thursday_open: timeString,
            friday_open: timeString,
            saturday_open: timeString,
            sunday_open: timeString,
        }));
    };

    const repeatClosingHours = (timeString) => {
        setWorkingHours((prev) => ({
            ...prev,
            monday_close: timeString,
            tuesday_close: timeString,
            wednesday_close: timeString,
            thursday_close: timeString,
            friday_close: timeString,
            saturday_close: timeString,
            sunday_close: timeString,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRadioInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value === "true", // Convert string to boolean
        }));
    };


    const handleThumbnailChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setThumbnailImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-profile-photo`, formData).then(response => {
                if (response.status === 200) {
                    const uploadedFileName = response.data;
                    setFormData(prev => ({
                        ...prev,
                        thumbnailImage: uploadedFileName,
                    }));
                    setIsThumbnailPicValid(true);
                } else {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                    notifyUser(
                        "Error",
                        "Please upload a square image with minimum 425px x 425px.",
                        "error"
                    );
                }
            })
                .catch(error => {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                });
        } else {
            setIsThumbnailPicValid(false);
            setErrors((prevErrors) => ({
                ...prevErrors,
                thumbnailImage: "Please select a valid image file",
            }));
            notifyUser("Invalid File", "Please select a valid image file", "warning");
        }
    };

    const handlePanChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setPanImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData).then(response => {
                if (response.status === 200) {
                    const uploadedFileName = response.data;
                    setFormData(prev => ({
                        ...prev,
                        panImage: uploadedFileName,
                    }));
                }
            }).catch(error => {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    panImage: "Please upload Pan Image.",
                }));
            });
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                panImage: "Please select a valid image file",
            }));
            notifyUser("Invalid File", "Please select a valid image file", "warning");
        }
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0],
        });
    };

    const handleCertificateChange = async (index, field, file) => {
        if (file && file.type.startsWith("image")) {
            const formData = new FormData();
            formData.append("photo", file);
    
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`,
                    formData
                );
    
                if (response.status === 200) {
                    handleCertifications(index, field, response.data);
                } else {
                    notifyUser("Error", "Failed to upload certificate image", "error");
                }
            } catch (error) {
                notifyUser("Error", "Error uploading certificate image", "error");
            }
        }
    };
    

    const handleCertifications = (index, field, value) => {
        const updatedCertifications = [...formData.certifications];
        updatedCertifications[index] = {
            ...updatedCertifications[index],
            [field]: value,
        };
        setFormData({ ...formData, certifications: updatedCertifications });
    };
    

    const addCertificationField = () => {
        setFormData({
            ...formData,
            certifications: [...formData.certifications, { name: '', image: null }],
        });
    };

    const handleSubmit = async () => {
        const daysOfWeek = [
            "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
        ];

    
        const workingHoursArray = daysOfWeek.map((day) => ({
            day,
            openingTime: workingHours[`${day.toLowerCase()}_open`] || "",
            closingTime: workingHours[`${day.toLowerCase()}_close`] || "",
        }));

        //e.preventDefault();
        const isFormValid = validateFormValues(formData);
        const thumbnailImage = formData.thumbnailImage
            ? formData.thumbnailImage.split("/").pop()
            : formData.thumbnailImage;
        const panImage = formData.panImage
            ? formData.panImage.split("/").pop()
            : formData.panImage;
        const certificateImage = formData.certificateImage
            ? formData.panImage.split("/").pop()
            : formData.certificateImage;
        // if (isFormValid) {
        const martDTO = {
            id: null,
            userId: authservice.getCurrentUserid(),
            name: formData.martName,
            businessDescription: formData.businessDescription,
            thumbnailImage: thumbnailImage,
            thumbnailDescription: formData.thumbnailDescription,
            businessAddress: formData.businessAddress,
            contactName: formData.contactName,
            contactNumber: formData.contactNumber,
            // certifications: [],
            CertificationDTO : formData.certifications.map(cert => ({
                name: cert.name,
                image: cert.image,
            })),
            homeDeliveryAvailable: formData.homeDeliveryAvailable,
            pickupAvailable: formData.pickupAvailable,
            workingHours: workingHoursArray,
            categories: formData.categories,
            subCategories: formData.subCategories,
            country: 'India',
            states: formData.states,
            cities: formData.cities,
            localities: formData.localities,
            neighbourhoods: formData.neighbourhoods,
            bankName: formData.bankName,
            accountHolderName: formData.accountHolderName,
            bankAccountNumber: formData.bankAccountNumber,
            ifsc: formData.ifsc,
            pan: formData.panNum,
            panImage: panImage,
            panLinkWithAadhar: formData.panLinkWithAadhar,
            gstNumber: formData.gstNumber,
            lattitude: null,
            longitude: null

        };

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/save`, martDTO)
            .then((response) => {
                if (response.status === 200) {
                    notifyUser("Success", "Mart added successfully", "success");
                    // if (currentStaff) {
                    //     setStaffs((prevStaffs) =>
                    //         prevStaffs.map((staff) => staff.userId === currentStaff.userId ? { ...staff, ...formData } : staff)
                    //     );
                    // } else {
                    setMarts([...marts, { ...formData }]);
                    //} 
                    //staffDetails();
                    //handleClosePopup();
                } else {
                    notifyUser("Error", response.data, type.DANGER)
                }
            })
            .catch((error) => {
                console.error("Error adding mart:", error);
                //notifyUser("Error", currentStaff ? "An error occurred while updating the staff." : "An error occurred while adding the staff.", "danger");
                notifyUser("Error", "Error adding mart", "danger")
            });
        // }
    }

    const handleAccount = (e) => {
        if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);

        const updatedBankAccountNumber = e.target.value.toUpperCase();
        setBankAccountNumber(updatedBankAccountNumber);

        bankAvailabilityTimer.current = setTimeout(async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/auth/check-bank-account-number?bankAccountNumber=${updatedBankAccountNumber}`
                );
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: response.data,
                }));
            } catch (error) {
                console.error("Error checking bank account number:", error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: "Unable to verify account number",
                }));
            }
        }, 1000);
    };

    // Clean up the timer on component unmount
    useEffect(() => {
        return () => {
            if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);
        };
    }, []);

    
    const validateFormValues = (values) => {
        const errors = {};
        let isValid = true;

        // Helper functions for specific validations
        const isEmpty = (value) => !value;
        const isValidPhone = (contactNumber) => mobilenumberRegex.test(contactNumber); // Adjust regex as per requirement
        const isValidIFSC = (ifsc) => ifscRegex.test(ifsc); // Example IFSC regex
        const isValidPAN = (panNum) => pan.test(panNum); // Example PAN regex
        const isValidGST = (gst) => gstNumber.test(gst); // Example GST regex

        // Required field validations
        if (isEmpty(values.martName)) {
            isValid = false;
            errors.martName = 'Mart name is required';
        }
        if (isEmpty(values.businessDescription)) {
            isValid = false;
            errors.businessDescription = 'Business description is required';
        }
        if (!thumbnailImage) {
            isValid = false;
            errors.thumbnailImage = 'Thumbnail Image is required';
        }
        // if (!values.isThumbnailPicValid) {
        //     isValid = false;
        //     errors.thumbnailImage = 'Please upload a square image with minimum 425px x 425px.';
        // }
        if (isEmpty(values.thumbnailDescription)) {
            isValid = false;
            errors.thumbnailDescription = 'Thumbnail description is required';
        }
        if (isEmpty(values.businessAddress)) {
            isValid = false;
            errors.businessAddress = 'Business address is required';
        }
        if (isEmpty(values.contactName)) {
            isValid = false;
            errors.contactName = 'Contact name is required';
        }
        if (!isValidPhone(values.contactNumber)) {
            isValid = false;
            errors.contactNumber = 'Valid phone number is required';
        }
        if (isEmpty(values.categories)) {
            isValid = false;
            errors.categories = 'At least one category is required';
        }
        if (isEmpty(values.subCategories)) {
            isValid = false;
            errors.subCategories = 'At least one subcategory is required';
        }
        // if (isEmpty(values.states)) {
        //     isValid = false;
        //     errors.states = 'State is required';
        // }
        // if (isEmpty(values.cities)) {
        //     isValid = false;
        //     errors.cities = 'City is required';
        // }
        // if (isEmpty(values.localities)) {
        //     isValid = false;
        //     errors.localities = 'Locality is required';
        // }
        // if (isEmpty(values.neighbourhoods)) {
        //     isValid = false;
        //     errors.neighbourhoods = 'Neighbourhood is required';
        // }
        if (isEmpty(values.bankName)) {
            isValid = false;
            errors.bankName = 'Bank name is required';
        }
        if (isEmpty(values.accountHolderName)) {
            isValid = false;
            errors.accountHolderName = 'Account holder name is required';
        }
        if (isEmpty(values.bankAccountNumber)) {
            isValid = false;
            errors.bankAccountNumber = 'Bank account number is required';
        }
        if (!isValidIFSC(values.ifsc)) {
            isValid = false;
            errors.ifsc = 'Valid IFSC code is required';
        }
        if (!isValidPAN(values.panNum)) {
            isValid = false;
            errors.panNum = 'Valid PAN is required';
        }
        // if (isEmpty(values.panImage)) {
        //     isValid = false;
        //     errors.panImage = 'PAN image is required';
        // }
        if (isEmpty(values.panLinkWithAadhar)) {
            isValid = false;
            errors.panLinkWithAadhar = 'PAN linked with Aadhar is required';
        }
        // if (!isValidGST(values.gstNumber)) {
        //     isValid = false;
        //     errors.gstNumber = 'Valid GST number is required';
        // }

        // Set errors and return overall validity
        setErrors(errors);
        return isValid;
    };


    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                const [getFullCategory] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/v2-getallcategories/${authservice.getCurrentUserid()}`),
                ]);
                setAllCategories(getFullCategory.data);
                if (props.fullCategory) {
                    props.fullCategory(getFullCategory.data);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };
        fetchAllCategories();
    }, [props]);

    const showCategory = true;

    const handleNewCategory = (data) => {
        setAllCategories((prevCategories) => [...prevCategories, data]);
    };

    // const handleCategory = (selectedItems) => {
    //     if (selectedItems.length) {
    //         setSelectedCategories(selectedItems[0].value);
    //     } else {
    //         setSelectedCategories(null);
    //     }
    // };

    // const handleSubCategory = (selectedItems) => {
    //     if (selectedItems.length) {
    //         setSelectedSubCategories(selectedItems[0].value);
    //     } else {
    //         setSelectedSubCategories(null);
    //     }
    // };

    const handleCategoryChange = (selectedCategories) => {
        const categoriesArray = selectedCategories.map(category => category.value);
        setFormData(prev => ({
            ...prev,
            categories: categoriesArray
        }));
    };
    
    const handleSubCategoryChange = (selectedSubCategories) => {
        const subCategoriesArray = selectedSubCategories.map(subCategory => subCategory.value);
        setFormData(prev => ({
            ...prev,
            subCategories: subCategoriesArray
        }));
    };

    const handleSetCategoryValidationMethod = (m) => {
        setCategoryValidation(m);
    };

    const handleSetSubCategoryValidationMethod = (m) => {
        setSubCategoryValidation(m);
    };

    useEffect(() => {
        const fetchAllLocations = async () => {
            try {
                const [getFullAddress] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getallstates`),
                ]);
                setAllLocations(getFullAddress.data);
                if (props.fullAddress) {
                    props.fullAddress(getFullAddress.data);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllLocations();
    }, [props]);

    const handleNewLocation = (data) => {
        setAllLocations((prevLocations) => [...prevLocations, data]);
    };

    const handleStateChange = (selectedStates) => {
        const statesArray = selectedStates.map(state => state.value);
        setFormData(prev => ({
            ...prev,
            states: statesArray
        }));
    };

    // const handleState = (e) => {
    //     if (e.length) {
    //         setSelectedState(e[0].value);
    //     } else {
    //         setSelectedState(null);
    //     }
    // };

    const handleCityChange = (selectedCities) => {
        const citiesArray = selectedCities.map(city => city.value);
        setFormData(prev => ({
            ...prev,
            cities: citiesArray
        }));
    };

    // const handleCity = (e) => {
    //     if (e.length) {
    //         setSelectedCity(e[0].value);
    //     } else {
    //         setSelectedCity(null);
    //     }
    // };

    const handleLocalityChange = (selectedLocalities) => {
        const localitiesArray = selectedLocalities.map(locality => locality.value);
        setFormData(prev => ({
            ...prev,
            localities: localitiesArray
        }));
    };

    // const handleLocality = (e) => {
    //     if (e.length) {
    //         setSelectedLocality(e[0].value);
    //     } else {
    //         setSelectedLocality(null);
    //     }
    // };

    const handleNeighbourhoodChange = (selectedNeighbourhoods) => {
        const neighbourhoodArray = selectedNeighbourhoods.map(neighbourhood => neighbourhood.value);
        setFormData(prev => ({
            ...prev,
            neighbourhoods: neighbourhoodArray
        }));
    };

    // const handleNeighbourhood = (e) => {
    //     if (e.length) {
    //         setSelectedNeighbourhood(e[0].value);
    //     } else {
    //         setSelectedNeighbourhood(null);
    //     }
    // };

    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                <TitleHeading text='Mart Management' />
                <Button
                    variant="primary"
                    className="mb-3 secondary"
                    onClick={() => setFormVisible(true)}>
                    Add Mart
                </Button>

                {formVisible && (
                    <div>
                        {/* <div className="popup-content"> */}
                        <Button variant="danger" className="popup-close-btn" onClick={() => setFormVisible(false)}>
                            &times;
                        </Button>
                        <Form>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Mart Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="martName"
                                            value={formData.martName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.martName && <p style={{ color: "red" }}>{errors.martName}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Business Description</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            as="textarea"
                                            name="businessDescription"
                                            value={formData.businessDescription}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.businessDescription && <p style={{ color: "red" }}>{errors.businessDescription}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <SetCategory
                                showCategory={showCategory}
                                setCategoryValidationMethod={handleSetCategoryValidationMethod}
                                setSubCategoryValidationMethod={handleSetSubCategoryValidationMethod}
                                //heading="Set Category"
                                isMulti
                                allCategories={allCategories}
                                _handleNewCategory={handleNewCategory}
                                newFilterForm={true}
                                selectedCategories={[selectedCategories]}
                                selectedSubCategories={[selectedSubCategories]}
                                _handleCategory={handleCategoryChange}
                                _handleSubCategory={handleSubCategoryChange}
                                wrapperClass="col-md-12"
                                labelClass="col-md-2"
                            />

                            <SetLocation
                                heading="Area Served"
                                isMulti
                                allLocations={allLocations}
                                _handleNewLocation={handleNewLocation}
                                newFilterForm={true}
                                //disabled={disable}
                                selectedStates={[selectedState]}
                                selectedCities={[selectedCity]}
                                selectedLocalities={[selectedLocality]}
                                selectedNeighbourhoods={[selectedNeighbourhood]}
                                _handleState={handleStateChange}
                                _handleCity={handleCityChange}
                                _handleLocality={handleLocalityChange}
                                _handleNeighbourhood={handleNeighbourhoodChange}
                                wrapperClass="col-md-12"
                                labelClass="col-md-2"
                            />

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Thumbnail Image</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="file"
                                            name="thumbnailImage"
                                            onChange={handleThumbnailChange}
                                            accept="image/*"
                                        />
                                        {errors.thumbnailImage && <p style={{ color: "red" }}>{errors.thumbnailImage}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Thumbnail Description</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            as="textarea"
                                            name="thumbnailDescription"
                                            value={formData.thumbnailDescription}
                                            onChange={handleInputChange}
                                        />
                                        {errors.thumbnailDescription && <p style={{ color: "red" }}>{errors.thumbnailDescription}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Business Address</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type='text'
                                            name="businessAddress"
                                            value={formData.businessAddress}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.businessAddress && <p style={{ color: "red" }}>{errors.businessAddress}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <Popup open={openDatePopup} closeOnDocumentClick onClose={closeDatePopup}>
                                <div className={`${styles.modal}`}>
                                    <div className={`${styles.modal_content}`}>
                                        <div className={`row ${styles.addpost_section}`}>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                {/* Start Time */}
                                                <div className="row form-row">
                                                    <div className="form-group col-md-3">
                                                        <FormLabels text={'Start Time: '} />
                                                    </div>
                                                    <div className="form-group col-md-9">
                                                        {!fromTime ? (
                                                            <TimePicker
                                                                defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                                                className={`${styles.basic_date_time}`}
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={30}
                                                                onOpenChange={handleOpenChangeFrom}
                                                                onChange={(time, timeString) => handleFromTime(timeString)}
                                                                open={openFrom}
                                                                addon={() => (
                                                                    <Button size="small" type="primary" onClick={handleCloseFrom}>
                                                                        Ok
                                                                    </Button>
                                                                )}
                                                            />
                                                        ) : (
                                                            <TimePicker
                                                                defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                                                defaultValue={moment(fromTime, 'h:mm a')}
                                                                className={`${styles.basic_date_time}`}
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={30}
                                                                onOpenChange={handleOpenChangeFrom}
                                                                onChange={(time, timeString) => handleFromTime(timeString)}
                                                                open={openFrom}
                                                                addon={() => (
                                                                    <Button size="small" type="primary" onClick={handleCloseFrom}>
                                                                        Ok
                                                                    </Button>
                                                                )}
                                                            />
                                                        )}
                                                        {inValidFromTime && (
                                                            <span className={`${styles.error}`}>Please Enter Valid Start Time</span>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* End Time */}
                                                <div className="row form-row">
                                                    <div className="form-group col-md-3">
                                                        <FormLabels text={'End Time: '} />
                                                    </div>
                                                    <div className="form-group col-md-9">
                                                        {!toTime ? (
                                                            <TimePicker
                                                                defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                                                className={`${styles.basic_date_time}`}
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={30}
                                                                onOpenChange={handleOpenChangeTo}
                                                                onChange={(time, timeString) => handleToTime(timeString)}
                                                                open={openTo}
                                                                addon={() => (
                                                                    <Button size="small" type="primary" onClick={handleCloseTo}>
                                                                        Ok
                                                                    </Button>
                                                                )}
                                                            />
                                                        ) : (
                                                            <TimePicker
                                                                defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                                                defaultValue={moment(toTime, 'h:mm a')}
                                                                className={`${styles.basic_date_time}`}
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={30}
                                                                onOpenChange={handleOpenChangeTo}
                                                                onChange={(time, timeString) => handleToTime(timeString)}
                                                                open={openTo}
                                                                addon={() => (
                                                                    <Button size="small" type="primary" onClick={handleCloseTo}>
                                                                        Ok
                                                                    </Button>
                                                                )}
                                                            />
                                                        )}
                                                        {inValidToTime && (
                                                            <span className={`${styles.error}`}>Please Enter Valid End Time</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                            <div className='col-md-8 text-center'>
                                <WorkingHours
                                    workingHours={workingHours}
                                    repeatOpeningHours={repeatOpeningHours}
                                    repeatClosingHours={repeatClosingHours}
                                    setWorkingHours={setWorkingHour}
                                    wrapperClass="col-md-12"
                                    labelClass="col-md-2"
                                />
                            </div>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Contact Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="contactName"
                                            value={formData.contactName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.contactName && <p style={{ color: "red" }}>{errors.contactName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Contact Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="tel"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.contactNumber && <p style={{ color: "red" }}>{errors.contactNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Home Delivery Available</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="homeDeliveryAvailable"
                                                    value={true}
                                                    checked={formData.homeDeliveryAvailable === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="homeDeliveryAvailable"
                                                    value={false}
                                                    checked={formData.homeDeliveryAvailable === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pickup Available</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="pickupAvailable"
                                                    value={true}
                                                    checked={formData.pickupAvailable === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="pickupAvailable"
                                                    value={false}
                                                    checked={formData.pickupAvailable === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Bank Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="bankName"
                                            value={formData.bankName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.bankName && <p style={{ color: "red" }}>{errors.bankName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Account Holder Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="accountHolderName"
                                            value={formData.accountHolderName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.accountHolderName && <p style={{ color: "red" }}>{errors.accountHolderName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Bank Account Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="bankAccountNumber"
                                            value={formData.bankAccountNumber}
                                            onChange={handleAccount && handleInputChange}
                                            required
                                        />
                                        {errors.bankAccountNumber && <p style={{ color: "red" }}>{errors.bankAccountNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>IFSC Code</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="ifsc"
                                            value={formData.ifsc}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.ifsc && <p style={{ color: "red" }}>{errors.ifsc}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pan</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="panNum"
                                            value={formData.panNum}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.panNum && <p style={{ color: "red" }}>{errors.panNum}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pan Image</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="file"
                                            name="panImage"
                                            onChange={handlePanChange}
                                            accept="image/*"
                                        />
                                        {errors.panImage && <p style={{ color: "red" }}>{errors.panImage}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pan Link with Aadhar</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="panLinkWithAadhar"
                                                    value={true}
                                                    checked={formData.panLinkWithAadhar === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="panLinkWithAadhar"
                                                    value={false}
                                                    checked={formData.panLinkWithAadhar === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>GST Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="gstNumber"
                                            value={formData.gstNumber}
                                            onChange={handleInputChange}
                                        />
                                        {errors.gstNumber && <p style={{ color: "red" }}>{errors.gstNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Certifications</Form.Label></div>
                                    <div className='col-md-10'>
                                        {formData.certifications.map((cert, index) => (
                                            <div key={index} className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Certification Name"
                                                    value={cert.name}
                                                    onChange={(e) =>
                                                        handleCertifications(index, 'name', e.target.value)
                                                    }
                                                    className="mb-2"
                                                />
                                                <Form.Control
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        handleCertificateChange(index, 'image', e.target.files[0])
                                                    }
                                                />
                                            </div>
                                        ))}
                                        <div className="popup-buttons">
                                            <Button className="secondary" variant="secondary" onClick={addCertificationField}>
                                                Add Certifications
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="secondary" onClick={() => setFormVisible(false)}>
                                    Cancel
                                </Button>
                                <Button className="secondary" variant="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                        {/* </div> */}
                    </div>
                )}

                {marts.length > 0 && (
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Mart Name</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Sub-Category</th>
                                <th>Contact Name</th>
                                <th>Contact Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {marts.map((mart, index) => (
                                <tr key={index}>
                                    <td>{mart.martName}</td>
                                    <td>{mart.businessDescription}</td>
                                    <td>{mart.category}</td>
                                    <td>{mart.subCategory}</td>
                                    <td>{mart.contactName}</td>
                                    <td>{mart.contactNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
            <div className={`listings row ${styles.large_rect}`}>
                {!props.isAdmin && <LargeRectangle spot={"-1"} />}
            </div>
            <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                {!props.isAdmin && <BillBoard spot={"-1"} />}
            </div>
        </div>
    );
};

export default ManageMart;
