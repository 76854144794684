import React, { Component } from 'react';
import axios from 'axios';
import { notifyUser, type } from '../utility/notifyUser';
import styles from './cover.module.css';
import cover from '../../assets/cover.jpg';
import Loader from '../utility/Loader';
import defaultUserProfile from '../../assets/defaultUserProfile.png';
import authservice from '../Authentication/service/authservice';
import { withRouter } from 'react-router-dom';
import CategoryData from './CategoryData';

class CategoryCover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            isProfileUploading: false,
            coverDataChanged: false,
            selectedImage: null,
            coverPhoto: "",
            profileDataChanged: false,
            profilePhoto: "",
            profileData: "",
            bio: '',
            channelName: '',
            category: '',
            subCategory: '',
            isEditing: false,
            isNameEditing: false,
            tempBio: '',
            tempName: '',
            channelName: "",
            isViewingBio: false,
            userName: '',
            isDialogOpen: false,
            listing_type: this.props.match.params.tab,
            subUserName: ''
        };
        this.url = window.location.href;
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.tabs = this.tabs.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setProfileData();
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (prevProps.match.params.tab !== match.params.tab) {
            this.setProfileData()
        }
    }

    tabs = (id) => {
        if (!id || this.state.listing_type === id) return;

        this.setState({ listing_type: id });
    };

    setProfileData = async () => {
        try {
            const catType = this.props.match.params.type.replace(/-/g, ' ');
            const subCatName = this.props.match.params.subcategory.replace(/-/g, ' ');
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/category/channel-profile/${catType}/${subCatName}`);
            this.setState({
                profileData: res.data,
                coverPhoto: res.data.coverImage,
                profilePhoto: res.data.profileImage,
                channelName: res.data.channelName,
                bio: res.data.description,
                userName: res.data.userName,
                category: res.data.category,
	            subCategory: res.data.subCategory,
                subUserName: res.data.subUserName,
            });
        } catch (error) {
            console.log("Something went wrong");
        }
    }

    uploadCover = (e) => {
        const file = e.target.files[0];
        this.setState({ coverDataChanged: true, selectedImage: file });

        if (file) {
            const coverImage = new FormData();
            coverImage.append("photo", file);
            this.setState({ isUploading: true });
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-cover-photo`, coverImage)
                .then(response => {
                    const coverPhotoResponse = response.data;
                    this.setState({ coverPhoto: coverPhotoResponse, isUploading: false });
                    const catType = this.props.match.params.type.replace(/-/g, ' ');
                    const subCatName = this.props.match.params.subcategory.replace(/-/g, ' ');
                    const data = {
                        coverImage: coverPhotoResponse,
                        userId: authservice.getCurrentUserid(),
                        userName: authservice.getCurrentUserName(),
                        category: catType,
                        subCategory: subCatName
                    };
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/category/update-channel-profile`, data)
                        .then(res => {
                            this.setState({ coverPhoto: res.data.coverImage });
                        });
                })
                .catch(error => {
                    notifyUser("Not Uploaded", error.response.data, type.WARNING);
                    Loader.stopSpinner();
                    this.setState({ isUploading: false });
                });
        }
    }

    uploadProfile = (e) => {
        const file = e.target.files[0];
        this.setState({ profileDataChanged: true, selectedImage: file });

        if (file) {
            const profileImage = new FormData();
            profileImage.append("photo", file);
            this.setState({ isProfileUploading: true });
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-profile-photo`, profileImage)
                .then(response => {
                    const profilePhotoResponse = response.data;
                    this.setState({ profilePhoto: profilePhotoResponse, isProfileUploading: false });
                    const catType = this.props.match.params.type.replace(/-/g, ' ');
                    const subCatName = this.props.match.params.subcategory.replace(/-/g, ' ');
                    const data = {
                        profileImage: profilePhotoResponse,
                        userId: authservice.getCurrentUserid(),
                        userName: authservice.getCurrentUserName(),
                        category: catType,
                        subCategory: subCatName
                    };
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/category/update-channel-profile`, data)
                        .then(res => {
                            this.setState({ profilePhoto: res.data.profileImage });
                        });
                })
                .catch(error => {
                    notifyUser("Not Uploaded", error.response.data, "warning");
                    Loader.stopSpinner();
                    this.setState({ isProfileUploading: false });
                });
        }
    }

    handleNameIconClick = () => {
        this.setState({ tempName: this.state.channelName, isNameEditing: true });
    }

    handleNameChange = (event) => {
        const newName = event.target.value;
        if (newName.length <= 45) {
            this.setState({ tempName: newName });
        }
        else
            return notifyUser("Profile Name", "Profile name should not more that 45 characters.", "warning")
    }

    handleNameSave = () => {
        const { tempName } = this.state;
        const catType = this.props.match.params.type.replace(/-/g, ' ');
        const subCatName = this.props.match.params.subcategory.replace(/-/g, ' ');
        const data = {
            channelName: tempName,
            userId: authservice.getCurrentUserid(),
            userName: authservice.getCurrentUserName(),
            category: catType,
            subCategory: subCatName
        };
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/category/update-channel-profile`, data)
            .then(res => {
                this.setState({ channelName: res.data.channelName, isNameEditing: false });
            });
    }

    handleIconClick = () => {
        this.setState({ tempBio: this.state.bio, isEditing: true });
    }

    handleBioChange = (e) => {
        this.setState({ tempBio: e.target.value });
    }

    handleSave = () => {
        const { tempBio } = this.state;
        const catType = this.props.match.params.type.replace(/-/g, ' ');
        const subCatName = this.props.match.params.subcategory.replace(/-/g, ' ');
        const data = {
            description: tempBio,
            userId: authservice.getCurrentUserid(),
            userName: authservice.getCurrentUserName(),
            category: catType,
            subCategory: subCatName
        };
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/category/update-channel-profile`, data)
            .then(res => {
                this.setState({ bio: res.data.description, isEditing: false });
            });
    }

    handleClose = () => {
        this.setState({ isEditing: false });
    }

    handleNameClose = () => {
        this.setState({ isNameEditing: false });
    }

    handleViewBio = () => {
        this.setState({ isViewingBio: true });
    }

    handleCloseBio = () => {
        this.setState({ isViewingBio: false });
    }

    truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.url).then(() => {
            console.log("Copied");
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    }

    showShareDialog = () => {
        this.setState({ isDialogOpen: true });
    };

    hideShareDialog = () => {
        this.setState({ isDialogOpen: false });
    };

    shareTo = (platform) => {
        const shareData = {
            title: `Check out ${this.props.match.params.type}`,
            url: window.location.href
        };

        let shareUrl;
        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData.url)}`;
                break;
            case 'whatsapp':
                shareUrl = `https://wa.me/?text=${encodeURIComponent(shareData.url)}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'skype':
                shareUrl = `https://web.skype.com/share?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'telegram':
                shareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareData.url)}}`;
                break;
            case 'snapchat':
                shareUrl = `https://www.snapchat.com/share?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'gmail':
                shareUrl = `mailto:?subject=${encodeURIComponent(shareData.title)}&body=${encodeURIComponent(shareData.url)}`;
                break;
            default:
                return;
        }
        window.open(shareUrl, '_blank');
        this.hideShareDialog();
    };

    render() {
        const { coverPhoto, profilePhoto, channelName, bio, isEditing, isNameEditing, tempBio, tempName, isViewingBio, isDialogOpen } = this.state;
        const { match } = this.props;
        const paragraphs = bio && bio.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ));

        return (
            <>
                <div className='row mr-0' style={{ backgroundColor: '#fff' }}>
                    <div className="container-fluid">
                        {/* Desktop view */}
                        <div className="d-none d-md-block">
                            <div className={`row no-gutters position-relative ${styles.profileBackground}`} style={{marginTop:"40px"}}>
                                <div className="col-md-9 position-relative">
                                    <img
                                        alt="Uploaded Pic"
                                        className={`img-fluid ${styles.coverImg}`}
                                        id="banner-ad"
                                        src={coverPhoto ? `${process.env.REACT_APP_IMAGES_URL + coverPhoto}` : cover}
                                        style={{ height: 'auto' }}
                                    />
                                    <label className={`btn ${styles.upload_btn} position-absolute`}>
                                        {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className='fas fa-camera'></i> : null}
                                        <input type="file" accept=".png, .jpg, .jpeg, .gif" hidden onChange={this.uploadCover} />
                                    </label>
                                </div>
                                <div className={`col-md-3 d-flex flex-column justify-content-center align-items-center position-relative ${styles.profileInfo}`}>
                                    <img
                                        src={profilePhoto ? `${process.env.REACT_APP_IMAGES_URL}${profilePhoto}` : defaultUserProfile}
                                        alt="Profile"
                                        className={`img-fluid ${styles.profile_image} mb-3`}
                                    />
                                    <label className={`btn ${styles.upload_btn_center} position-absolute`}>
                                        {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className='fas fa-camera'></i> : null}
                                        <input type="file" accept=".png, .jpg, .jpeg, .gif" hidden onChange={this.uploadProfile} />
                                    </label>
                                    <h2 className={styles.profileName}>{channelName} {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className='fa fa-pencil' onClick={this.handleNameIconClick}></i> : null} </h2>
                                    {isNameEditing && (
                                        <div className={`row ${styles.name_popup}`}>
                                            <div>
                                                <textarea
                                                    value={tempName}
                                                    onChange={this.handleNameChange}
                                                    className={styles.name_input}
                                                />
                                            </div>
                                            <div className={styles.buttonContainer}>
                                                <button onClick={this.handleNameSave} className={styles.save_button}>Save</button>
                                                <button onClick={this.handleNameClose} className={styles.save_button}>Close</button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="about-section text-left p-3">
                                        <div className={styles.bio_label}>
                                            <span>About {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className={`fa fa-pencil ${styles.edit_icon}`} onClick={this.handleIconClick}></i> : null}</span>
                                        </div>
                                        {bio && <p className={styles.bio_text}>
                                            {this.truncateText(bio, 12)}
                                            {bio.split(' ').length > 12 && (
                                                <>
                                                    <button onClick={this.handleViewBio} className={styles.read_more_button}>
                                                        Read more
                                                    </button>

                                                </>
                                            )}
                                        </p>}
                                        <button className={styles.share_button} onClick={this.showShareDialog}><i className='fas fa-share-square'> Share</i></button>
                                        {isDialogOpen && (
                                            <div className="sharedialog">
                                                <h2>Share To</h2>
                                                <input
                                                    type="text"
                                                    value={window.location.href}
                                                    style={{ width: '80%', padding: '5px', marginBottom: '10px' }}
                                                    readOnly
                                                />
                                                <button className="copylink" onClick={this.copyToClipboard}><i className="fa fa-copy"></i></button>
                                                <div className="sharebuttons">
                                                    <div className="sharebutton fb" onClick={() => this.shareTo('facebook')}></div>
                                                    <div className="sharebutton whatsapp" onClick={() => this.shareTo('whatsapp')}></div>
                                                    <div className="sharebutton twitter" onClick={() => this.shareTo('twitter')}></div>
                                                    <div className="sharebutton skype" onClick={() => this.shareTo('skype')}></div>
                                                    <div className="sharebutton telegram" onClick={() => this.shareTo('telegram')}></div>
                                                    <div className="sharebutton snapchat" onClick={() => this.shareTo('snapchat')}></div>
                                                    <div className="sharebutton linkedin" onClick={() => this.shareTo('linkedin')}></div>
                                                    <div className="sharebutton gmail" onClick={() => this.shareTo('gmail')}></div>
                                                </div>
                                                <button className="shareclose" onClick={this.hideShareDialog}>Close</button>
                                            </div>
                                        )}
                                        {isEditing && (
                                            <div className={`row ${styles.bio_popup}`}>
                                                <div>
                                                    <textarea
                                                        value={tempBio}
                                                        onChange={this.handleBioChange}
                                                        className={styles.bio_input}
                                                    />
                                                </div>
                                                <div className={`row ${styles.buttonContainer}`}>
                                                    <button onClick={this.handleSave} className={styles.save_button}>Save</button>
                                                    <button onClick={this.handleClose} className={styles.save_button}>Close</button>
                                                </div>
                                            </div>
                                        )}
                                        {isViewingBio && (
                                            <div className={styles.full_bio_popup}>
                                                <p>{paragraphs}</p>
                                                <button onClick={this.handleCloseBio} className={styles.close_button}>Close</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile view */}
                        <div className="d-block d-md-none text-center">
                            <img
                                alt="Uploaded Pic"
                                className="img-fluid w-100 mb-3"
                                id="banner-ad"
                                src={coverPhoto ? `${process.env.REACT_APP_IMAGES_URL + coverPhoto}` : cover}
                                style={{ height: 'auto' }}
                            />
                            <label className={`btn ${styles.upload_btn_mob} position-absolute`}>
                                {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className='fas fa-camera'></i> : null}
                                <input type="file" accept=".png, .jpg, .jpeg, .gif" hidden onChange={this.uploadCover} />
                            </label>
                            <div className="d-block d-md-none text-center">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-start justify-content-center">
                                        <img
                                            src={profilePhoto ? `${process.env.REACT_APP_IMAGES_URL}${profilePhoto}` : defaultUserProfile}
                                            alt="Profile"
                                            className={`img-fluid ${styles.profile_image} mb-3 pl-1`}
                                            style={{ width: '120px', height: 'auto', objectFit: 'cover' }}
                                        />
                                        <label className={`btn ${styles.upload_btn_center_mob} position-absolute`}>
                                            {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className='fas fa-camera'></i> : null}
                                            <input type="file" accept=".png, .jpg, .jpeg, .gif" hidden onChange={this.uploadProfile} />
                                        </label>
                                    </div>
                                    <div className="col-8 text-left">
                                        <h2 className={styles.profileNameMob}>
                                            {channelName} {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className='fa fa-pencil' onClick={this.handleNameIconClick}></i> : null}
                                        </h2>
                                        {isNameEditing && (
                                            <div className={`row ${styles.name_popup}`}>
                                                <div>
                                                    <textarea
                                                        value={tempName}
                                                        onChange={this.handleNameChange}
                                                        className={styles.name_input}
                                                    />
                                                </div>
                                                <div className={styles.buttonContainer}>
                                                    <button onClick={this.handleNameSave} className={styles.save_button}>Save</button>
                                                    <button onClick={this.handleNameClose} className={styles.save_button}>Close</button>
                                                </div>
                                            </div>
                                        )}
                                        <div className="about-section p-1">
                                            <div className={styles.bio_label_Mob}>
                                                <span>
                                                    About {this.state.subUserName === authservice.getCurrentUserName() || this.state.userName === authservice.getCurrentUserName() ? <i className={`fa fa-pencil ${styles.edit_icon}`} onClick={this.handleIconClick}></i> : null}
                                                </span>
                                            </div>
                                            {bio && (
                                                <p className={styles.bio_text_mob}>
                                                    {this.truncateText(bio, 12)}
                                                    {bio.split(' ').length > 12 && (
                                                        <>
                                                            <button onClick={this.handleViewBio} className={styles.read_more_button}>
                                                                Read more
                                                            </button>
                                                        </>
                                                    )}
                                                </p>
                                            )}
                                            <button className={styles.share_button_mob} onClick={this.showShareDialog}><i className='fas fa-share-square'> Share</i></button>
                                            {isDialogOpen && (
                                                <div className={styles.sharedialog_mob}>
                                                    <h2>Share To</h2>
                                                    <input
                                                        type="text"
                                                        value={window.location.href}
                                                        style={{ width: '80%', padding: '5px', marginBottom: '10px' }}
                                                        readOnly
                                                    />
                                                    <button className="copylink" onClick={this.copyToClipboard}><i className="fa fa-copy"></i></button>
                                                    <div className="sharebuttons">
                                                        <div className="sharebutton fb" onClick={() => this.shareTo('facebook')}></div>
                                                        <div className="sharebutton whatsapp" onClick={() => this.shareTo('whatsapp')}></div>
                                                        <div className="sharebutton twitter" onClick={() => this.shareTo('twitter')}></div>
                                                        <div className="sharebutton skype" onClick={() => this.shareTo('skype')}></div>
                                                        <div className="sharebutton telegram" onClick={() => this.shareTo('telegram')}></div>
                                                        <div className="sharebutton snapchat" onClick={() => this.shareTo('snapchat')}></div>
                                                        <div className="sharebutton linkedin" onClick={() => this.shareTo('linkedin')}></div>
                                                        <div className="sharebutton gmail" onClick={() => this.shareTo('gmail')}></div>
                                                    </div>
                                                    <button className="shareclose" onClick={this.hideShareDialog}>Close</button>
                                                </div>
                                            )}
                                            {isEditing && (
                                                <div className={`row ${styles.bio_popup}`}>
                                                    <div>
                                                        <textarea
                                                            value={tempBio}
                                                            onChange={this.handleBioChange}
                                                            className={styles.bio_input}
                                                        />
                                                    </div>
                                                    <div className={styles.buttonContainer}>
                                                        <button onClick={this.handleSave} className={styles.save_button}>Save</button>
                                                        <button onClick={this.handleClose} className={styles.save_button}>Close</button>
                                                    </div>
                                                </div>
                                            )}
                                            {isViewingBio && (
                                                <div className={styles.full_bio_popup_mob}>
                                                    <p>{paragraphs}</p>
                                                    <button onClick={this.handleCloseBio} className={styles.close_button}>Close</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row no-gutters position-relative' style={{ backgroundColor: '#fff' }}>
                    <CategoryData {...this.props} />
                </div>
            </>
        );
    }
}

export default withRouter(CategoryCover);
