import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Errors } from '../UIElements/errors/Errors'
import { FormLabels } from '../UIElements/Form_label/FormLabels'
import { notifyUser, type } from '../utility/notifyUser'
import { NewFilterForm } from './NewFilterForm'

export const SelectFilter = (props) => {

    const [newFilterForm, setNewFilterForm] = useState(false)
    const [error, setError] = useState('');
    const isAdmin = useSelector(state => state.isAdmin)

    const validateForm = () => {
        let elementId = '';
        if (props.id === 'Category' && props.selectedItems.length === 0) {
            setError('Please Select any Category')
            elementId = 'Category'
        }
        if (newFilterForm) {
            elementId = 'add' + props.id
            //notifyUser('Fields', 'Yous have some unsaved changes', type.WARNING);
        }
        return elementId;
    }

    useEffect(() => {
        if (props.id === 'Category')
            props.setCategoryValidationMethod && props.setCategoryValidationMethod(validateForm)
        if (props.id === 'SubCategory')
            props.setSubCategoryValidationMethod && props.setSubCategoryValidationMethod(validateForm)
        if (props.id === 'State')
            props.setStateValidationMethod && props.setStateValidationMethod(validateForm)
        if (props.id === 'City')
            props.setCityValidationMethod && props.setCityValidationMethod(validateForm)
        if (props.id === 'Locality')
            props.setLocalityValidationMethod && props.setLocalityValidationMethod(validateForm)
        if (props.id === 'Neighbourhood')
            props.setNeighbourhoodValidationMethod && props.setNeighbourhoodValidationMethod(validateForm)
    }, [validateForm])

    const handleChange = (e) => {
        let selctedList = []
        if (props.isMulti) {
            if (Array.isArray(e)) selctedList = e
            else selctedList = [...props.selectedItems, e]
        } else if (e) {
            selctedList.push(e)
        }
        if (props.onChange)
            props.onChange(selctedList)

        if (error && selctedList.length > 0)
            setError('')
    }

    const showNewFilterForm = () => {
        setNewFilterForm(true)
    }

    const hideNewFilterForm = () => {
        setNewFilterForm(false)
    }

    return (
        <>
            <div className={`form-row ${props.wrapperClass}`}>
                <div className={`form-group ${props.labelClass} label`}>
                    <FormLabels text={props.id === 'SubCategory' && !props.showCategory ? 'Select/Add Name' : `Select ${props.id}`} htmlFor={props.id} />
                </div>
                <div id={props.id} className="form-group col-md-8">
                    <Select
                        placeholder={props.id === 'SubCategory' && !props.showCategory ? 'Select Name' : props.id}
                        value={props.selectedItems}
                        onChange={handleChange}
                        options={props.options}
                        isClearable
                        isMulti={props.isMulti}
                        isDisabled={props.disabled}
                    />
                    <Errors id="cateErrorInfo">{error}</Errors>
                </div>
                {(props.disabled || newFilterForm || !props.newFilterForm) || (!isAdmin && props.id === 'State') ? null :
                (props.id === 'SubCategory' || props.id === 'Category' || isAdmin && props.id === 'State' || isAdmin && props.id === 'City' || isAdmin && props.id === 'Locality' || props.location.pathname !== '/manageprofile' && props.id === 'Neighbourhood') ?
                    <div className="form-group add_button col-md-1">
                        <button className="btn btn-link" type="button"
                            onClick={showNewFilterForm}>+ Add</button>
                    </div> : null
                }
            </div>
            {(!props.disabled && newFilterForm) ?
                <NewFilterForm
                    {...props}
                    handleCancel={hideNewFilterForm}
                    newFilter={handleChange}
                /> : null}
        </>
    )
}