import React from 'react'
import styles from './labels.module.css'

export const Labels = (props) => {
    return (
        <label className={`${styles.preview_label} ${props.className ? props.className : ''}`}
            style={props.style ? props.style : null}>
            {props.text ? props.text : props.children}
        </label>
    )
}