import React, { Component } from 'react'
import { DatePicker, TimePicker, Button } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { connect } from 'react-redux';
import styles from './forms/addpost.module.css'
import { Labels } from '../UIElements/ContentPost_Label/Labels';

class WorkingHours extends Component {

    state = {}
    componentDidMount() {
        this.saveWorkingHours(this.props.post_data)
    }

    saveWorkingHours = (data) => {
        this.setState({
            mondayFromTime: (data.mondayOpen) ? moment(data.mondayOpen, 'h:mm a') : null,
            mondayToTime: (data.mondayClose) ? moment(data.mondayClose, 'h:mm a') : null,
            tuesdayFromTime: (data.tuesdayOpen) ? moment(data.tuesdayOpen, 'h:mm a') : null,
            tuesdayToTime: (data.tuesdayClose) ? moment(data.tuesdayClose, 'h:mm a') : null,
            wednesdayFromTime: (data.wednesdayOpen) ? moment(data.wednesdayOpen, 'h:mm a') : null,
            wednesdayToTime: (data.wednesdayClose) ? moment(data.wednesdayClose, 'h:mm a') : null,
            thursdayFromTime: (data.thursdayOpen) ? moment(data.thursdayOpen, 'h:mm a') : null,
            thursdayToTime: (data.thursdayClose) ? moment(data.thursdayClose, 'h:mm a') : null,
            fridayFromTime: (data.fridayOpen) ? moment(data.fridayOpen, 'h:mm a') : null,
            fridayToTime: (data.fridayClose) ? moment(data.fridayClose, 'h:mm a') : null,
            saturdayFromTime: (data.saturdayOpen) ? moment(data.saturdayOpen, 'h:mm a') : null,
            saturdayToTime: (data.saturdayClose) ? moment(data.saturdayClose, 'h:mm a') : null,
            sundayFromTime: (data.sundayOpen) ? moment(data.sundayOpen, 'h:mm a') : null,
            sundayToTime: (data.sundayClose) ? moment(data.sundayClose, 'h:mm a') : null,
        })

        this.props.setWorkingHours(data.mondayOpen, "monday_open")
        this.props.setWorkingHours(data.mondayClose, "monday_close")
        this.props.setWorkingHours(data.tuesdayOpen, "tuesday_open")
        this.props.setWorkingHours(data.tuesdayClose, "tuesday_close")
        this.props.setWorkingHours(data.wednesdayOpen, "wednesday_open")
        this.props.setWorkingHours(data.wednesdayClose, "wednesday_close")
        this.props.setWorkingHours(data.thursdayOpen, "thursday_open")
        this.props.setWorkingHours(data.thursdayClose, "thursday_close")
        this.props.setWorkingHours(data.fridayOpen, "friday_open")
        this.props.setWorkingHours(data.fridayClose, "friday_close")
        this.props.setWorkingHours(data.saturdayOpen, "saturday_open")
        this.props.setWorkingHours(data.saturdayClose, "saturday_close")
        this.props.setWorkingHours(data.sundayOpen, "sunday_open")
        this.props.setWorkingHours(data.sundayClose, "sunday_close")
    }

    isAnyTimePickerActive = () => {
        const self = this.state;
        return self.openMondayFrom ||
            self.openTuesdayFrom ||
            self.openWednesdayFrom ||
            self.openThursdayFrom ||
            self.openFridayFrom ||
            self.openSaturdayFrom ||
            self.openSundayFrom ||
            self.openMondayTo ||
            self.openTuesdayTo ||
            self.openWednesdayTo ||
            self.openThursdayTo ||
            self.openFridayTo ||
            self.openSaturdayTo ||
            self.openSundayTo;
    }

    openTimePicker = (selector) => {
        if (!this.isAnyTimePickerActive())
            this.setState({ [selector]: true })
    }

    closeTimePicker = (selector) => {
        this.setState({ [selector]: false })
    }

    handleToTime = (timeString, toTimeVariableName, fromTimeVariableName) => {
        if (this.props.workingHours[fromTimeVariableName] && timeString.length > 0) {
            const fromTimeStamp = moment(this.props.workingHours[fromTimeVariableName], 'hh:mm a')
            const toTimeStamp = moment(timeString, 'hh:mm a')
            if (toTimeStamp.isAfter(fromTimeStamp)) {
                this.props.setWorkingHours(timeString, toTimeVariableName)
                this.setState({ inValidToTime: false, inValidFromTime: false })
            } else {
                this.setState({ inValidToTime: true })
            }
        } else {
            this.props.setWorkingHours(timeString, toTimeVariableName)
        }
    }

    handleFromTime = (timeString, fromTimeVariableName, toTimeVariableName) => {
        if (this.props.workingHours[toTimeVariableName] && timeString.length > 0) {
            const fromTimeStamp = moment(timeString, 'hh:mm a')
            const toTimeStamp = moment(this.props.workingHours[toTimeVariableName], 'hh:mm a')
            if (toTimeStamp.isAfter(fromTimeStamp)) {
                this.props.setWorkingHours(timeString, fromTimeVariableName)
                this.setState({ inValidToTime: false, inValidFromTime: false })
            } else {
                this.setState({ inValidToTime: true })
            }
        } else {
            this.props.setWorkingHours(timeString, fromTimeVariableName)
            //this.setState({ [fromTimeVariableName]: timeString })
        }
        if (!timeString.length) {
            this.props.setWorkingHours(timeString, toTimeVariableName)
        }
    }

    repeatOpeningHours = (fromTime, toTime) => {
        if (fromTime) {
            if (!this.state.tuesdayFromTime && !this.state.wednesdayFromTime && !this.state.thursdayFromTime &&
                !this.state.fridayFromTime && !this.state.saturdayFromTime && !this.state.sundayFromTime) {
                this.setState({
                    tuesdayFromTime: fromTime,
                    wednesdayFromTime: fromTime,
                    thursdayFromTime: fromTime,
                    fridayFromTime: fromTime,
                    saturdayFromTime: fromTime,
                    sundayFromTime: fromTime,
                })
                this.props.repeatOpeningHours(fromTime.format('hh:mm a'));
            }
        }
        if (toTime) {
            if (!this.state.tuesdayToTime && !this.state.wednesdayToTime && !this.state.thursdayToTime &&
                !this.state.fridayToTime && !this.state.saturdayToTime && !this.state.sundayToTime) {
                this.setState({
                    tuesdayToTime: toTime,
                    wednesdayToTime: toTime,
                    thursdayToTime: toTime,
                    fridayToTime: toTime,
                    saturdayToTime: toTime,
                    sundayToTime: toTime,
                })
                this.props.repeatClosingHours(toTime.format('hh:mm a'))
            }
        }
    }

    render() {
        return (
            <div>
                <h2 className={`${styles.preview_header} text-left`}>Working Hours</h2>
                <div className="row form-row">
                    <div className="col-md-12">{this.state.inValidFromTime ?
                        <div className='error'>Please Enter Valid Start Time</div> : null}
                        {this.state.inValidToTime ?
                            <div className='error'>Please Enter Valid End Time</div> : null}
                    </div>
                </div>
                <div className="row form-row">
                    <div className="col-md-12">
                        <div className="row form-row">
                            <Labels className="form-group col-md-4" text={'Monday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"mondayOpen"}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    value={this.state.mondayFromTime}
                                    onOpenChange={() => this.openTimePicker('openMondayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ mondayToTime: null }) }
                                        this.setState({ mondayFromTime: time })
                                        this.handleFromTime(timeString, 'monday_open', 'monday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openMondayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => {
                                            this.closeTimePicker('openMondayFrom');
                                            this.repeatOpeningHours(this.state.mondayFromTime, false)
                                        }}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"mondayClose"}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    value={this.state.mondayToTime}
                                    onOpenChange={() => this.openTimePicker('openMondayTo')} onChange={(time, timeString) => {
                                        this.setState({ mondayToTime: time })
                                        this.handleToTime(timeString, 'monday_close', 'monday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openMondayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => {
                                            this.closeTimePicker('openMondayTo');
                                            this.repeatOpeningHours(false, this.state.mondayToTime)
                                        }}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                        <div className="row form-row">
                            <Labels className="form-group col-md" text={'Tuesday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"tuesdayOpen"}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    value={this.state.tuesdayFromTime}
                                    onOpenChange={() => this.openTimePicker('openTuesdayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ tuesdayToTime: null }) }
                                        this.setState({ tuesdayFromTime: time })
                                        this.handleFromTime(timeString, 'tuesday_open', 'tuesday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openTuesdayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openTuesdayFrom')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"tuesdayClose"}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    value={this.state.tuesdayToTime}
                                    onOpenChange={() => this.openTimePicker('openTuesdayTo')} onChange={(time, timeString) => {
                                        this.setState({ tuesdayToTime: time })
                                        this.handleToTime(timeString, 'tuesday_close', 'tuesday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openTuesdayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openTuesdayTo')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                        <div className="row form-row">
                            <Labels className="form-group col-md" text={'Wednesday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"wednesdayOpen"}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    value={this.state.wednesdayFromTime}
                                    onOpenChange={() => this.openTimePicker('openWednesdayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ wednesdayToTime: null }) }
                                        this.setState({ wednesdayFromTime: time })
                                        this.handleFromTime(timeString, 'wednesday_open', 'wednesday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openWednesdayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openWednesdayFrom')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"wednesdayClose"}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    value={this.state.wednesdayToTime}
                                    onOpenChange={() => this.openTimePicker('openWednesdayTo')} onChange={(time, timeString) => {
                                        this.setState({ wednesdayToTime: time })
                                        this.handleToTime(timeString, 'wednesday_close', 'wednesday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openWednesdayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openWednesdayTo')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                        <div className="row form-row">
                            <Labels className="form-group col-md" text={'Thursday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"thursdayOpen"}
                                    value={this.state.thursdayFromTime}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openThursdayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ thursdayToTime: null }) }
                                        this.setState({ thursdayFromTime: time })
                                        this.handleFromTime(timeString, 'thursday_open', 'thursday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openThursdayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openThursdayFrom')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"thursdayClose"}
                                    value={this.state.thursdayToTime}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openThursdayTo')} onChange={(time, timeString) => {
                                        this.setState({ thursdayToTime: time })
                                        this.handleToTime(timeString, 'thursday_close', 'thursday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openThursdayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openThursdayTo')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                        <div className="row form-row">
                            <Labels className="form-group col-md" text={'Friday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"fridayOpen"}
                                    value={this.state.fridayFromTime}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openFridayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ fridayToTime: null }) }
                                        this.setState({ fridayFromTime: time })
                                        this.handleFromTime(timeString, 'friday_open', 'friday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openFridayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openFridayFrom')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"fridayClose"}
                                    value={this.state.fridayToTime}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openFridayTo')} onChange={(time, timeString) => {
                                        this.setState({ fridayToTime: time })
                                        this.handleToTime(timeString, 'friday_close', 'friday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openFridayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openFridayTo')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                        <div className="row form-row">
                            <Labels className="form-group col-md" text={'Saturday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"saturdayOpen"}
                                    value={this.state.saturdayFromTime}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openSaturdayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ saturdayToTime: null }) }
                                        this.setState({ saturdayFromTime: time })
                                        this.handleFromTime(timeString, 'saturday_open', 'saturday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openSaturdayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openSaturdayFrom')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"saturdayClose"}
                                    value={this.state.saturdayToTime}
                                    defaultOpenValue={moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openSaturdayTo')} onChange={(time, timeString) => {
                                        this.setState({ saturdayToTime: time })
                                        this.handleToTime(timeString, 'saturday_close', 'saturday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openSaturdayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openSaturdayTo')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                        <div className="row form-row">
                            <Labels className="form-group col-md" text={'Sunday'} />
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"sundayOpen"}
                                    value={this.state.sundayFromTime}
                                    defaultOpenValue={(this.props.post_data.sundayOpen) ? moment(this.props.post_data.sundayOpen, "h:mm a") : moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openSundayFrom')} onChange={(time, timeString) => {
                                        if (!timeString.length) { this.setState({ sundayToTime: null }) }
                                        this.setState({ sundayFromTime: time })
                                        this.handleFromTime(timeString, 'sunday_open', 'sunday_close')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openSundayFrom} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openSundayFrom')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                            <div className="form-group col-md">
                                <TimePicker use12Hours format="h:mm a" minuteStep={30} className="premium_time_picker" id={"sundayClose"}
                                    value={this.state.sundayToTime}
                                    defaultOpenValue={(this.props.post_data.sundayClose) ? moment(this.props.post_data.sundayClose, "h:mm a") : moment('00:00:00', 'h:mm a')}
                                    onOpenChange={() => this.openTimePicker('openSundayTo')} onChange={(time, timeString) => {
                                        this.setState({ sundayToTime: time })
                                        this.handleToTime(timeString, 'sunday_close', 'sunday_open')
                                    }}
                                    popupClassName="working_hour_time_picker"
                                    open={this.state.openSundayTo} addon={() => (
                                        <Button size="small" type="primary" onClick={() => this.closeTimePicker('openSundayTo')}>
                                            Ok
                                        </Button>
                                    )} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        post_data: state.post_data
    }
}
export default connect(mapStateToProps, null)(WorkingHours)